import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import axios from "axios";
import dayjs from 'dayjs';
export default defineComponent({
  name: 'News',
  setup: function setup() {
    var arr = ref([]);
    var router = useRouter();

    var showMore = function showMore() {
      ElMessage({
        message: '暂时没有更多',
        type: 'warning'
      });
    };

    var handleClick = function handleClick(item) {
      router.push({
        path: '/news/item',
        query: {
          news_id: item.news_id
        }
      });
    };

    onMounted(function () {
      axios.get("https://picbook.bcepd.cn/d-bcepd/cmpcms/userfree/pageListChannel?site_id=bcepdsite&channel_id=1747829686942828554").then(function (res) {
        arr.value = res.data.r.rs;
      });
    });
    onMounted(function () {
      window.scrollTo(0, 0);
    });
    return {
      arr: arr,
      dayjs: dayjs,
      handleClick: handleClick,
      showMore: showMore
    };
  }
});